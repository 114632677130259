/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from 'react-dom/client'
import HyperDX from '@hyperdx/browser';
import App from './App'
import reportWebVitals from './reportWebVitals'


const container = document.getElementById('root')
const root = createRoot(container!)
const {hostname} = new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT!)
console.log({ 
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
  service: `${process.env.REACT_APP_FIREBASE_PROJECT_ID||''}-frontend`,
  tracePropagationTargets: [
    new RegExp(hostname, 'i')
  ], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
} )
HyperDX.init({ 
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
  service: `${process.env.REACT_APP_FIREBASE_PROJECT_ID||''}-frontend`,
  tracePropagationTargets: [
    new RegExp(hostname, 'i')
  ], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
HyperDX.setGlobalAttributes({
  appId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
});

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ('serviceWorker'  in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
   .then(function(registration) {
    console.log('Registration was successful: ', registration)
   })
   .catch(function(e) {
    console.error('Registration has filed', e)
   })
 }
