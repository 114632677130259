/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { useLazyQuery } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
  useIonLoading,
  useIonModal,
  useIonViewDidEnter,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ModalLayout } from 'src/components'
import ActionListItem from 'src/components/ActionListItem/ActionListItem'
import { SelectionTri } from 'src/components/Selection/SelectionTri'
import { GET_ACTIONS_LIST } from 'src/graphql/actions-types.graphql'
import { ActionEntity } from 'src/models/gql/graphql'
import {
  resetFicheActions,
  resetFilters,
} from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import { getOrgId } from 'src/utils'
import styled from 'styled-components'

import './Actions.css'

/* interface GroupedActionEntity {
  count: number
  [key: string]: unknown // accepte les clés de type chaîne de caractères
}
export interface ActionEntityWithCount extends ActionEntity {
  count: number
} */

const Actions: React.FC = () => {
  const history = useHistory()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [page, setPage] = useState(1) // La page actuelle
  const pageSize = 50
  const [pageCount, setPageCount] = useState(0)

  const [allData, setAllData] = useState([] as any[])
  const [getInitData, { loading, data }] = useLazyQuery(GET_ACTIONS_LIST)
  const [present, dismiss] = useIonModal(SelectionTri, {
    onDismiss: (dataModal: string, role: string) => dismiss(dataModal, role),
  })
  const [statistiques, setStatistiques] = useState({
    nbAction: null,
    nbrUsagers: null,
    nbrMenage: null,
  })
  const { filters } = useSelector((state: RootState) => state.ficheActions)
  const [desc, setDesc] = useState('desc')

  const openModalTri = (): void => {
    present({
      onWillDismiss: (ev: CustomEvent<any>) => {
        if (ev.detail.role === 'confirm') {
          setDesc(`${ev.detail.data}`)
          setPage(1)
        }
      },
    })
  }

  const checkIfAnyFieldHasValue = (obj: Record<string, any>): boolean => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== '') {
          return true
        }
      }
    }
    return false
  }

  const getFieldsWithValues = (
    obj: Record<string, any>,
  ): Record<string, any> => {
    const fieldsWithValues: Record<string, any> = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== '') {
          fieldsWithValues[key] = obj[key]
        }
      }
    }
    return fieldsWithValues
  }

  function filterUndefinedFields(fields: any) {
    const filteredFields: Record<string, any> = {} // Add index signature to allow indexing with a string
    for (const key in fields) {
      if (fields[key] !== undefined) {
        filteredFields[key] = fields[key]
      }
    }
    return filteredFields
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const hasValue = checkIfAnyFieldHasValue(filters)
        let filtersAPI
        let params
        if (hasValue) {
          const {
            usagerFirstName,
            usagerLastName,
            created_date,
            isUnknown,
            usagerLocalisation,
            usagerSituationFamiliale,
            services,
            categories,
            actions,
            createdBy,
          } = getFieldsWithValues(filters)

          filtersAPI = {
            firstName: usagerFirstName,
            lastName: usagerLastName,
            createdDate: created_date,
            isUnknown,
            location: usagerLocalisation,
            familySituation: usagerSituationFamiliale,
            services,
            categories,
            actions,
            createdBy,
          }

          // Construction des paramètres de la chaîne de requête
          params = new URLSearchParams(
            filterUndefinedFields(filtersAPI),
          ).toString()
        }

        const response = await fetch(
          `${
            process.env.REACT_APP_AUTH_URI
          }/api/statistique-actions?organization=${getOrgId()}&${params}`,
        )

        const jsonData = await response.json()

        if (jsonData?.length > 0) {
          setStatistiques({
            nbAction: jsonData[0].data,
            nbrUsagers: jsonData[1].data,
            nbrMenage: jsonData[2].data,
          })
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error)
      }
    }

    // Appelle la fonction pour effectuer la requête au chargement du composant
    fetchData()
  }, [])

  useEffect(() => {
    const hasValue = checkIfAnyFieldHasValue(filters)
    console.log({hasValue})
    if (hasValue ||desc) {
      const {
        usagerFirstName,
        usagerLastName,
        created_date,
        isUnknown,
        usagerLocalisation,
        usagerSituationFamiliale,
        services,
        categories,
        actions,
        createdBy,
      } = getFieldsWithValues(filters)
      getInitData({
        notifyOnNetworkStatusChange: true,
        variables: {
          filters: {
            and: [
              {
                organization: { id: { eq: getOrgId() } },
              },
              usagerFirstName
                ? { usagers: { firstname: { containsi: usagerFirstName } } }
                : {},

              usagerLastName
                ? { usagers: { lastname: { containsi: usagerLastName } } }
                : {},
              usagerSituationFamiliale
                ? {
                    usagers: {
                      familyStatus: {
                        id: {
                          in: usagerSituationFamiliale,
                        },
                      },
                    },
                  }
                : {},

              usagerLocalisation
                ? { usagers: { addressZipcode: { eq: usagerLocalisation } } }
                : {},

              created_date ? { actionDate: { eq: created_date } } : {},
              createdBy ? { author: { id: { eq: createdBy } } } : {},
              isUnknown ? { nbUnknownUsagers: { gt: 0 } } : {},

              services
                ? {
                    service: {
                      id: {
                        in: services,
                      },
                    },
                  }
                : {},

              actions
                ? {
                    actionType: {
                      id: {
                        in: actions,
                      },
                    },
                  }
                : {},

              categories
                ? {
                    actionType: {
                      category: {
                        id: {
                          in: categories,
                        },
                      },
                    },
                  }
                : {},
            ],
          },
          sort: [`actionDate:${desc.toUpperCase()}`],
          pagination: { page, pageSize },
        },
      })
    }
  }, [desc, filters, getInitData, page, pageSize])
  

  const getActionList = async (): Promise<void> => {
    getInitData({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            /* {
              author: { id: { eq: getUserId() } },
            }, */
          ],
        },
        sort: ['actionDate:DESC'],
        pagination: { page, pageSize },
      },
    })
    const response = await fetch(
      `${
        process.env.REACT_APP_AUTH_URI
      }/api/statistique-actions?organization=${getOrgId()}`,
    )

    const jsonData = await response.json()

    if (jsonData?.length > 0) {
      setStatistiques({
        nbAction: jsonData[0].data,
        nbrUsagers: jsonData[1].data,
        nbrMenage: jsonData[2].data,
      })
    }
  }



  useEffect(() => {
    if (data && data.actions) {
      if (page === 1) {
        setAllData(data?.actions?.data as any)
      } else {
        setAllData((prevData) => [...prevData, ...(data?.actions?.data as any)])
      }
      setPageCount(data?.actions?.meta?.pagination?.pageCount)
    }
  }, [data])

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      store.dispatch(resetFicheActions())
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const handleClick = (): void => {
    history.push('/create/action')
  }
  const resetAllFilter = (): void => {
    setPage(1)
    store.dispatch(resetFilters())
    getActionList()
  }

  return (
    <ActionWrapper>
      <ModalLayout title="Liste des fiches actions">
        <IonGrid className="ion-no-padding fixed-actions">
          <IonRow className="fiche-actions">
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="flag-action" />
                <h3 className="count">{statistiques.nbAction || 0}</h3>
                <p className="subtitle">actions </p>
              </div>
            </IonCol>
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="usagers-action" />
                <h3 className="count">{statistiques.nbrUsagers || 0}</h3>
                <p className="subtitle">usagers </p>
              </div>
            </IonCol>
            <IonCol className="ion-justify-content-center" size="4">
              <div className="list-actions">
                <IonIcon className="action-home" />
                <h3 className="count">{statistiques.nbrMenage || 0}</h3>
                <p className="subtitle">ménages</p>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="action-tabs">
            <IonCol>
              <div className="filter-action">
                <div
                  className={
                    checkIfAnyFieldHasValue(filters)
                      ? 'filter-button has-close'
                      : 'filter-button'
                  }
                >
                  <IonButton
                    color="primary"
                    size="small"
                    className="btn-filter-action"
                    onClick={() => history.replace('/actions/filters')}
                  >
                    Filtres{' '}
                  </IonButton>
                  {checkIfAnyFieldHasValue(filters) && (
                    <span className="filter-close" onClick={resetAllFilter}>
                      {checkIfAnyFieldHasValue(filters)
                        ? Object.keys(getFieldsWithValues(filters)).length
                        : ''}
                    </span>
                  )}
                </div>
                <div className="filter-button">
                  <IonButton
                    color="primary"
                    size="small"
                    className="btn-filter-action"
                    onClick={openModalTri}
                  >
                    Tri :{' '}
                    {desc === 'desc' ? 'plus récentes' : 'plus anciennes '}
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="liste-actions">
          <IonRow>
            <IonCol>
              <div>
                {allData &&
                  allData &&
                  allData?.length > 0 &&
                  allData?.map((actions: any) => (
                    <ActionListItem
                      action={(actions as unknown) as ActionEntity}
                      key={actions.id}
                    />
                  ))}
                {pageCount > page && (
                  <IonInfiniteScroll
                    onIonInfinite={(ev) => {
                      setPage(page + 1)
                      setTimeout(() => ev.target.complete(), 500)
                    }}
                  >
                    <IonInfiniteScrollContent />
                  </IonInfiniteScroll>
                )}
                <IonFab onClick={handleClick} className="ion-fab">
                  <IonFabButton>
                    <IonIcon icon={add} />
                  </IonFabButton>
                </IonFab>
              </div>

              {!loading &&
                data &&
                data.actions &&
                data.actions?.data.length === 0 &&
                allData?.length === 0 && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      {checkIfAnyFieldHasValue(filters) &&
                      Object.keys(getFieldsWithValues(filters)).length > 0
                        ? 'Aucun résultat trouvé pour vos filtres'
                        : "Vous n'avez pas d'actions"}
                    </IonCol>
                  </IonRow>
                )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </ActionWrapper>
  )
}

const ActionWrapper = styled(IonPage)`
  .usagers-segments {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    background: var(--ion-color-main-background);
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
  }
  .usagers-segments-content {
    margin-top: 40px;
  }
`
export default Actions
